<style lang="scss">
.align-left {
  text-align: left;
}
</style>
<template>
  <div>
    <v-card >
      <v-toolbar color="grey lighten-4" class="c-view-title">
        <div class="o-icon-menu">
          <svg class="icon-svg"
          width="18.5"
          height="18.5"
          viewBox="0 0 22.5 14.5"
          fill="var(--fillColor)">
          <path style="d:path(var(--bar1));" />
          <path class="st0" style="d:path(var(--bar3));" />
          </svg>
        </div>
        <div class="c-view-title-text">
          <v-toolbar-title class="c-view-title-text__h1">
            Configuración gerneral
          </v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
      </v-card-text>
    </v-card>
    <!-- Logos y Firmas -->
    <v-row>
      <v-col cols="4">
        <v-card
          class="mx-auto"
          max-width="344"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4 align-left">
                Logos
              </div>
              <v-list-item-title class="headline mb-1 align-left">
                Firmas & logos
              </v-list-item-title>
              <v-list-item-subtitle class="align-left">
                Personaliza tus firmas y logos
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="#2d00d7"
            >
            <v-icon color="#FFFFFF">mdi-folder-image</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              depressed
              color="primary"
              outlined
              rounded
              text
              @click="modalFirmas()"
            >
              Configurar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- componente modal formulario logo y firmas -->
    <firmaslogos ref="logofirma"></firmaslogos>
  </div>
</template>
<script>
export default {
  components: {
    firmaslogos: () => import('./modalfirmas/formularioFirmas.vue'),
  },
  data() {
    return {
    };
  },
  methods: {
    modalFirmas() {
      this.$refs.logofirma.openmodal();
    },
  },
};
</script>
